"use client";

import { useState, useRef, useEffect } from "react";
import { CollapsingField } from "@/shared/ui/CollapsingField/CollapsingField";
import "./Workflow.css";
import { IWorkflow } from "./types";

const MAX_ITEMS = 6;

const WorkflowClient: React.FC<IWorkflow> = ({ content, isCollapsible = false }) => {
  // Для управления открытием/закрытием отдельных CollapsingField
  const [openIndex, setOpenIndex] = useState(-1);

  // Для «Показать все» / «Скрыть»
  const [showAll, setShowAll] = useState(false);

  // Реф на блок со скрытыми элементами
  const hiddenBlockRef = useRef<HTMLDivElement>(null);

  // Управляем max-height скрытого контейнера
  const [hiddenHeight, setHiddenHeight] = useState("0px");

  const getNumber = (n: number) => (n < 10 ? `0${n}` : String(n));

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  // Рассчитываем реальную высоту скрытого блока, чтобы плавно анимировать max-height
  useEffect(() => {
    if (hiddenBlockRef.current && isCollapsible) {
      if (showAll) {
        const scrollHeight = hiddenBlockRef.current.scrollHeight;
        setHiddenHeight(scrollHeight + 400 + "px");
      } else {
        setHiddenHeight("0px");
      }
    }
  }, [showAll, isCollapsible]);

  const handleShowAll = () => {
    setShowAll((prev) => !prev);
  };

  // Если не включена collapsible-логика, просто показываем всё
  if (!isCollapsible) {
    return (
      <section className="workflow wrapper">
        <h3 className="workflow__title">{content.title || ""}</h3>
        <div className="workflow__container">
          {content.workflowItems.map((item, index) => (
            <CollapsingField
              key={index}
              title={`${getNumber(index + 1)} ${item.itemText}`}
              text={item.detailsText || ""}
              iconStyle="black"
              type="html"
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </div>
      </section>
    );
  }

  // Если collapsible-логика включена:
  const firstItems = content.workflowItems.slice(0, MAX_ITEMS);
  const hiddenItems = content.workflowItems.slice(MAX_ITEMS);

  return (
    <section className="workflow wrapper">
      <h3 className="workflow__title">{content.title || ""}</h3>

      {/* Первые N (например, 6) пунктов */}
      <div className="workflow__container">
        {firstItems.map((item, index) => (
          <CollapsingField
            key={index}
            title={`${getNumber(index + 1)} ${item.itemText}`}
            text={item.detailsText || ""}
            iconStyle="black"
            type="html"
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
        {/* Контейнер для остальных пунктов с плавным раскрытием */}
        {hiddenItems.length > 0 && (
          <div
            className="workflow__hidden"
            ref={hiddenBlockRef}
            style={{
              maxHeight: hiddenHeight,
              overflow: "hidden",
              transition: "max-height 0.5s ease",
            }}
          >
            {hiddenItems.map((item, i) => {
              const actualIndex = MAX_ITEMS + i;
              return (
                <CollapsingField
                  key={actualIndex}
                  title={`${getNumber(actualIndex + 1)} ${item.itemText}`}
                  text={item.detailsText || ""}
                  iconStyle="black"
                  type="html"
                  isOpen={openIndex === actualIndex}
                  onToggle={() => handleToggle(actualIndex)}
                />
              );
            })}
          </div>
        )}
        {/* Кнопка "Показать все / Скрыть" + полоска (hr) */}
        {hiddenItems.length > 0 && (
          <div className="workflow__show-all" onClick={handleShowAll}>
            <hr className="workflow__divider" />
            <div className="workflow__arrow-down">
              {showAll ? content.hideBttn : content.showAllBttn}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default WorkflowClient;
