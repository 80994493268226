import emailjs from "@emailjs/browser";
import { IQuizData } from "../types";
import { containsTestString } from "@/shared/lib/containsTestString";

export const handleMultiSelectChange = (
  questionId: number,
  value: string[],
  setAnswers: React.Dispatch<React.SetStateAction<{ [key: number]: string[] }>>
) => {
  setAnswers((prevAnswers) => ({
    ...prevAnswers,
    [questionId]: value, // Просто заменяем старый массив на новый
  }));
};

// Фабрика хэндлера завершения квиза
export const createHandleQuizComplete = (
  setUserAnswers: React.Dispatch<
    React.SetStateAction<{ [key: number]: string }>
  >,
  setIsQuizCompleted: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return (answers: { [key: number]: string }) => {
    setUserAnswers(answers);
    setIsQuizCompleted(true);
  };
};

export const normalizeAnswers = (
  answers: { [key: number]: string | string[] },
  quizData: IQuizData
): { [key: string]: string } => {
  const normalized: { [key: string]: string } = {};

  quizData.quizQuestions.forEach((question) => {
    const answerValue = answers[question.id];
    if (answerValue) {
      if (Array.isArray(answerValue)) {
        normalized[question.question] = answerValue
          .map((value) => {
            const option = question.quizOptions.find(
              (opt) => opt.value === value
            );
            return option ? option.label : value;
          })
          .join(", ");
      } else {
        const option = question.quizOptions.find(
          (opt) => opt.value === answerValue
        );
        normalized[question.question] = option ? option.label : answerValue;
      }
    }
  });

  return normalized;
};

// Хэндлер изменения ответа в квизе
export const handleAnswerChange = (
  questionId: number,

  value: string | string[],

  setAnswers: React.Dispatch<
    React.SetStateAction<{ [key: number]: string | string[] }>
  >
) => {
  setAnswers((prevAnswers) => ({
    ...prevAnswers,

    [questionId]: value,
  }));
};

// Хэндлер перехода к следующему вопросу
export const handleNext = (
  currentQuestionIndex: number,
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>,
  maxIndex: number
) => {
  if (currentQuestionIndex < maxIndex) {
    setCurrentQuestionIndex((prev) => prev + 1);
  }
};

// Хэндлер перехода к предыдущему вопросу
export const handlePrev = (
  currentQuestionIndex: number,
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  if (currentQuestionIndex > 0) {
    setCurrentQuestionIndex((prev) => prev - 1);
  }
};

// Хэндлер изменения контактных данных
export const handleContactChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,

  setContactInfo: React.Dispatch<
    React.SetStateAction<{ name: string; contact: string; comment: string }>
  >
) => {
  const { name, value } = e.target;

  setContactInfo((prevState) => ({
    ...prevState,

    [name]: value,
  }));
};

// Хэндлер отправки квиза — вызывает onComplete с ответами
export const handleSubmitQuiz = (
  answers: { [key: number]: string },
  onComplete: (answers: { [key: number]: string }) => void
) => {
  onComplete(answers);
};

// Фабрика хэндлера отправки формы контактов
export const createHandleContactSubmit = (userAnswers: {
  [key: string]: string;
}) => {
  return (contactInfo: { name: string; contact: string; comment?: string }) => {
    
    if (containsTestString(contactInfo)) {
      console.log("Тестирующая бригада детектед");
      return null;
    }

    // 1. Генерируем HTML для списка вопросов-ответов
    const answersHtml = Object.entries(userAnswers)
      .map(([question, answer]) => {
        return `
          <li style="margin-bottom: 8px; line-height: 1.6;">
            <strong style="color: #bba187;">${question}:</strong> ${answer}
          </li>
        `;
      })
      .join("");

    // 2. Если есть comment, генерируем отдельный блок, иначе пустую строку
    const commentBlock = contactInfo.comment
      ? `
        <div style="margin-bottom: 20px;">
          <h3 style="margin: 0 0 10px 0; font-size: 1.125rem; font-weight: 500; color: #b5b1b1;">
            Comment:
          </h3>
          <pre style="
            white-space: pre-wrap;
            word-wrap: break-word;
            color: #ffffff;
            background-color: #191919;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #bba187;
            margin: 0; /* обнулить отступы pre */
          ">
${contactInfo.comment}
          </pre>
        </div>
      `
      : "";

    // 3. Склеиваем итоговый HTML
    const htmlContent = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Quiz Submission</title>
  </head>
  <body style="margin: 0; padding: 0; background-color: #191919; font-family: Arial, sans-serif;">
    <!-- Основная таблица, центрируем контент -->
    <table width="100%" cellspacing="0" cellpadding="0" border="0" style="background-color: #191919; width: 100%; padding: 40px 0;">
      <tr>
        <td align="center">
          <!-- Внутренняя таблица с фиксированной шириной -->
          <table width="600" cellspacing="0" cellpadding="20" border="0" style="background-color: #323232; border-radius: 8px; color: #ffffff; text-align: left;">
            <tr>
              <td style="padding: 30px;">
                <!-- Заголовок письма -->
                <h2 style="margin: 0 0 20px 0; font-size: 1.75rem; font-weight: 500; color: #bba187;">
                  Quiz Submission
                </h2>

                <!-- Контактная информация -->
                <div style="margin-bottom: 20px;">
                  <h3 style="
                    margin: 0 0 10px 0;
                    font-size: 1.125rem;
                    font-weight: 500;
                    color: #b5b1b1;
                  ">
                    Contact Information:
                  </h3>
                  <p style="margin: 0; color: #ffffff;">
                    <strong>Name:</strong> ${contactInfo.name}
                  </p>
                  <p style="margin: 0; color: #ffffff;">
                    <strong>Contact:</strong> ${contactInfo.contact}
                  </p>
                </div>

                <!-- Ответы -->
                <div style="margin-bottom: 20px;">
                  <h3 style="
                    margin: 0 0 10px 0;
                    font-size: 1.125rem;
                    font-weight: 500;
                    color: #b5b1b1;
                  ">
                    Answers:
                  </h3>
                  <!-- Вместо <pre> используем <ul> -->
                  <ul style="
                    margin: 0;
                    padding-left: 20px;
                    color: #ffffff;
                    list-style-type: disc;
                  ">
                    ${answersHtml}
                  </ul>
                </div>

                <!-- Комментарий (если есть) -->
                ${commentBlock}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </body>
</html>
    `;

    // 4. Готовим объект для отправки в email.js
    //    Важно, чтобы ключи совпадали с теми, которые ожидает ваш шаблон
    const emailData = {
      // Пример: если в шаблоне emailjs есть {{htmlContent}}, используем {{{htmlContent}}}
      htmlContent,
      // Также можно указать имя отправителя/почту, если нужно
      from_name: contactInfo.name,
      reply_to: contactInfo.contact,
    };

    // 5. Отправляем
    emailjs
      .send(
        "service_m51rkrc",
        "template_gxchugo",
        emailData,
        "oTnq4ufxCtTUQjvqL"
      )
      .then(
        (result) => {
          console.log("Email sent:", result.text);
          // alert('Thank you for completing the quiz!');
        },
        (error) => {
          console.error("Email error:", error.text);
          alert("Failed to send email. Please try again later.");
        }
      );
  };
};
